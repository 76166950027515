// require("dotenv").config()
// NOT WORKING

export const config = {
  "django_api": "https://unilab.fh-krems.ac.at:8000/api/"
  // "django_api": "http://127.0.0.1:8000/api/"
  // "django_api": process.env["API_URL"]
}

// console.log('Current directory: ' + process.cwd());
// change to https://unilab.fh-krems.ac.at:8000/api/ for running build
// change to https://127.0.0.1:8000/api/ for development